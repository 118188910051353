.base-view {
	&-wrapper {
		width: 100%;
	}

	&-child {
		@apply m-auto px-4 py-2;
		max-width: 750px;
	}
}
